<template>
  <div>
    <report-module v-if="d_reportList.list.length > 0" ref="ref_report_module" :p_reportData="d_reportData" :p_reportList="d_reportList" :f_selectThisreport="f_selectThisreport" :f_reportViewRecord="f_reportViewRecord" :p_reportDashboard="d_reportDashboard"></report-module>
  </div>
</template>

<script>
import FileSaver from 'file-saver';
import moment from 'moment';
import PoleStarService from '@/services/polestar';
import ReportModule from '@/components/widgets/ReportModule';
import ReportService from '@/services/report';
import store from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'reportmoduleiframe',
  data () {
    return {
      ReportModule: ReportModule,
      f_reportViewRecord: '',
      d_reportList: {
        'list': [],
        'show': false,
        'index': ''
      },
      d_reportDashboard: {
        'change': 0,
        'other': { 'order': [], 'list': {} },
        'dashboard_group': { 'order': [], 'list': {} }
      },
      d_reportData: { 'list': [], 'index': '', 'status': 0, 'table_index': '' },
      d_timerControlUpdate: { 'timer': 0, 'status': 'passive' },
    };
  },
  components: {
    ReportModule,
  },
  computed: mapGetters({
    help: 'help'
  }),
  created: function () {
    if (this.$route.query && this.$route.query.project_id_list) {
      this.d_projectIdList = this.$route.query.project_id_list;
    }
    if (this.d_projectIdList) {
      this.f_reportList();
    } else {
      alert('project_id_list not defined');
    }
  },
  mounted () {},
  destroyed () {},
  methods: {
    f_reportDashboardList: function () {
      if (this.d_projectIdList) {
        // this.d_reportDashboard.change == 0;
        this.d_reportDashboard.other = { 'order': [], 'list': {} };
        this.d_reportDashboard.dashboard_group = { 'order': [], 'list': {} };
        let query = 'project_id=' + this.d_projectIdList;
        ReportService.report_dashboard_list(query)
          .then(resp => {
            if (resp.data.status_code === '2000') {
              // this.d_reportDashboard.change == 1;
              this.d_reportDashboard.other = resp.data.other;
              this.d_reportDashboard.dashboard_group = resp.data.dashboard_group;
              this.$refs.ref_report_module.f_createDashboardRows();
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      } else {
        alert('Proje belirtiniz.');
      }
    },
    f_reportList: function () {
      let query = 'project_id=' + this.d_projectIdList;
      ReportService.report_list(query)
        .then(resp => {
          if (resp.data.status_code === '2000') {
            this.d_reportList.list = resp.data.list;
            this.f_reportDashboardList();
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_selectThisreport: function (report_index) {
      this.d_reportData.index = '';
      this.d_reportData.status = 0;
      this.d_reportList.index = report_index;
      this.d_reportList.show = false;
      let query = 'report_id_list=' + this.d_reportList.list[report_index].id;
      ReportService.report_details(query)
        .then(resp => {
          this.d_reportData.status = 1;
          if (resp.data.status_code === '2000') {
            this.d_reportData.list = resp.data.list;
            if (this.d_reportData.list.length === 1) {
              this.d_reportData.index = 0;
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    /*
    f_reportViewRecord: function (report_index, record_type, view_list = [], table_index = '') {
      let query = 'project_id=' + this.d_reportData.list[report_index].project_id;
      query += '&report_id=' + this.d_reportData.list[report_index].id;
      query += '&record_type=' + record_type;
      let data = { 'view_list': view_list, 'table_index': table_index };
      ReportService.report_view_record(query, data)
        .then(resp => {
          if (resp.data.status_code === '2000') {
            alert('successfully recorded');
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    }
    */
  },
  watch: {}
};

</script>

<style type="text/css"></style>

